import React from "react";
import { Card, Form, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import back from "../../../assets/images/back (3).png";
import { Editor } from "@tinymce/tinymce-react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import Navbar from "../../../layouts/Navbar";
import Sidebar from "../../../layouts/Sidebar";
import Orange from "../../../assets/images/green2.svg";
import { toast } from "react-toastify";
import {
    authToken, baseUrl, changeTitleStr, getQutation
} from "../../../actions";
// "/" + props.authRole + "quotations/create-quotation/customerDetails" + props.templateId
const role = localStorage.getItem('userRole');

class ContentBody extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: "",
            data: [],
            error: ""
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.prevPage = this.prevPage.bind(this);

    }
    handleChange(content) {
        this.setState({ content });
    }
    prevPage() {
        const { history } = this.props;
        history.push(`/${role}/quotations/${this.props.match.params.add}/customerDetails/${this.props.match.params.id}/${this.props.match.params.quotId}`);
    }
    nextPage() {
        const { history } = this.props;
        history.push(`/${role}/quotations/${this.props.match.params.add}/location/${this.props.match.params.id}/${this.props.match.params.quotId}`)
    }
    handleSubmit(event) {
        event.preventDefault();
        const { content } = this.state;
        const { quotationData } = this.props;
        if (content !== "") {
            if (content !== quotationData?.templateContent) {
                const requestOptions = {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authToken}`,
                    },
                    body: String(content)
                };
                fetch(`${baseUrl}staff/quotations/${quotationData.quotationId}/templateContent`, requestOptions)
                    .then(res => {
                        if (res.status >= 400) {
                            toast("Server responds with error!");
                            throw new Error("Server responds with error!")
                        }
                        return res.json()
                    })
                    .then(res => {
                        this.setState({
                            data: res
                        })
                        toast(res.data);
                        this.nextPage();
                    },
                        err => {
                            this.setState({
                                error: err
                            })
                        })
            } else {
                this.nextPage();
            }
        } else {
            toast("Please Enter Some Content")
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.quotationData !== this.props.quotationData) {
            this.setState({
                content: this.props.quotationData?.templateContent,
            });
        }
    }
    componentDidMount() {
        if (this.props.match.params.quotId !== "null") {
            this.props.dispatch(getQutation(this.props.match.params.quotId));
        }
    }

    render() {
        const { history } = this.props;

        return (
            <div>
                <Navbar />
                <div className="dashboard-page">
                    <div className="dashboard-sidebar">
                        <Sidebar />
                    </div>
                    <div className="dashboard-main">
                        <Card className="staff-page">
                            <img src={Orange} alt="..." className="head-img" />
                            <h5 className='po_link' onClick={() => history.push("/" + role + "/quotations")}>Quotations</h5>
                            <i className="fas fa-arrow-right" style={{ margin: "10px 0 0 -10px" }}></i>
                            <h6 >{changeTitleStr(this.props.match.params.add)}</h6>
                            <hr className="line-new"></hr>
                            <div className="order-page-title">
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/customerDetails/${this.props.match.params.id}/${this.props.match.params.quotId}`}>
                                    Customer Details
                                </Link>
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/contentBody/${this.props.match.params.id}/${this.props.match.params.quotId}`} className="active" >
                                    Content of the Letter
                                </Link>
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/location/${this.props.match.params.id}/${this.props.match.params.quotId}`}  >
                                    Location
                                </Link>
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/quotationBody/${this.props.match.params.id}/${this.props.match.params.quotId}`}  >
                                    Quotation Body
                                </Link>
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/footer/${this.props.match.params.id}/${this.props.match.params.quotId}`}  >
                                    Footer
                                </Link>
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/termsAndConditions/${this.props.match.params.id}/${this.props.match.params.quotId}`}  >
                                    Terms & Conditions
                                </Link>
                                <Link to={`/${role}/quotations/${this.props.match.params.add}/prepared/${this.props.match.params.id}/${this.props.match.params.quotId}`}  >
                                    Prepared by
                                </Link>
                            </div>
                            <form onSubmit={this.handleSubmit} style={{ padding: "20px", paddingLeft: "28px" }}>
                                <p className="form-label">Letter Body</p>
                                <Editor
                                    // apiKey="2j6wvm4x2pa25n680e9pbtskcfhyi2qdsvjut2c24rm83049"
                                    apiKey="8n2aarggnqa5hgtufqzwy5feg5vxivofahd8jh5x31pwslb0"
                                    value={this.state.content}
                                    init={{
                                        height: 350,
                                        menubar: false

                                    }}
                                    onEditorChange={this.handleChange}
                                />

                                <br></br><br></br>
                                <Row>
                                    <Col md={2}> <div onClick={this.prevPage} type="button" className="cancel-btn" ><center><img style={{ marginRight: "10px" }} src={back} />Back</center></div></Col>
                                    <Col md={7}></Col>
                                    <Col md={3}> <button type="submit" className="modal-btn save">Next</button></Col>
                                </Row>



                            </form>
                        </Card>
                    </div>
                </div>
            </div>
        );
    }


}

const mapStateToProps = (state) => {
    let initValues;
    let quote = state.boq.boqIdData;
    let customer = state.customer.customer;
    let newCustomer = quote.billOfQuantityId !== null ? {
        'customerInitials': quote ? quote.customerInitials : '',
        'firstName': quote ? quote.customerFirstName : '',
        'lastName': quote ? quote.customerLastName : '',
        'phone': quote ? quote.customerNumber : '',
        'email': quote ? quote.customerEmail : '',
        'address': quote ? quote.customerAddress : '',
        'city': quote ? quote.customerCity : '',
        'state': quote ? quote.customerState : '',
        'pincode': quote ? quote.customerPincode : '',
    } : {};

    initValues = Object.keys(customer).length !== 0 ? customer : newCustomer;

    return {
        initialValues: initValues,
        quotationData: state.qutation.quotationData,
    }
}
const VendorDetailsForm = reduxForm({
    form: 'purchaseOrderFormRedux',
    destroyOnUnmount: false,
    enableReinitialize: true,
    // validate
})(ContentBody)

export default connect(mapStateToProps)(VendorDetailsForm);