import React from "react";
import { Col, Row, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import {
    authToken,
    baseUrl,
    getPoById,
    getQutationTemplates,
    previewPo,
    cancelPo,
    generatePo,
    sendPo,
    clonePo,
    revisePo,
    getPoVerifyProduts,
    deletePo,
    clearPoVerifyProducts,
    getPoInwardList,
    getTitleStr,
    getPoMailDetails,
    sendPoMail
} from "../../../../actions";
import { Editor } from "@tinymce/tinymce-react";
import { Field, reduxForm, reset } from 'redux-form';
import Orange from "../../../../assets/images/green2.svg";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Navbar from "../../../../layouts/Navbar";
import Sidebar from "../../../../layouts/Sidebar";
import VenderPoView from "../../vendorpo/VenderPoView";
import VerifyProducts from "../../vendorpo/verifyProducts";
import InwardProducts from '../../vendorpo/Inward';
import DeletePopUp from "../../vendorpo/deletePopup";
import CloneStatus from "../../../../Components/cloningStatus";
import ClonePopup from "../../../../Components/clonePopup";
import EmailPopup from '../../../../Components/emailPopup';

const role = localStorage.getItem("userRole");


class PoTermsAndConditions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdown: [],
            content: "",
            dataObj: this.props.poData?.termsAndConditions !== "null" ? this.props.poData?.termsAndConditions : "",
            error: {},
            id: this.props.match.params.id,
            editShow: false,
            status: "DRAFT",
            verifyPopup: false,
            deletePopup: false,
            inwardPopup: false,
            cloned: false,
            clonePopup: false,
            cloneStr: "",
            cloneShow: false,
            vendorItem: []
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.editVendorShow = this.editVendorShow.bind(this);
        this.editVendorClose = this.editVendorClose.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.goToPoPage = this.goToPoPage.bind(this);
        this.deletePoHandler = this.deletePoHandler.bind(this);
        this.generatePoHandler = this.generatePoHandler.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.deleteItemClose = this.deleteItemClose.bind(this);
        this.cancelPoHandler = this.cancelPoHandler.bind(this);
        this.sendPohandler = this.sendPohandler.bind(this);
        this.clonePoHandler = this.clonePoHandler.bind(this);
        this.revisePoHandler = this.revisePoHandler.bind(this);
        this.verifyShowPopup = this.verifyShowPopup.bind(this);
        this.verifyClosePopup = this.verifyClosePopup.bind(this);
        this.inwardOpen = this.inwardOpen.bind(this);
        this.inwardClose = this.inwardClose.bind(this);
        this.sendMailOpen = this.sendMailOpen.bind(this);
        this.sendMailClose = this.sendMailClose.bind(this);
        this.sendPoMailSubmit = this.sendPoMailSubmit.bind(this);
        this.getCCs = this.getCCs.bind(this);
    }
    goToPoPage() {
        const { status } = this.state
        if (status === "DRAFT") {
            this.setState({
                editShow: false
            })
        } else {
            this.revisePoHandler();
        }
    }
    prevPage() {
        window.location.hash = `/${role}/vendor_po/footer/${this.props.match.params.id}`
    }
    generatePoHandler() {
        this.setState({
            editShow: false
        })
        this.props.dispatch(generatePo(this.props.match.params.id));
    }
    sendPohandler() {
        this.props.dispatch(sendPo(this.props.match.params.id));
    }
    clonePoHandler() {
        this.setState({
            cloned: true,
            clonePopup: true,
            cloneStr: "Cloning Purchase Order...Please wait..."
        })
        this.props.dispatch(clonePo(this.props.match.params.id));
    }
    revisePoHandler() {
        this.setState({
            cloned: false,
            clonePopup: true,
            cloneStr: "Revising Purchase Order...Please wait..."
        })
        this.props.dispatch(revisePo(this.props.match.params.id));
    }
    cancelPoHandler() {
        this.props.dispatch(cancelPo(this.props.match.params.id));
    }
    verifyShowPopup() {
        this.props.dispatch(getPoVerifyProduts(this.props.match.params.id));
        this.setState({
            verifyPopup: true
        })
    }
    verifyClosePopup() {
        this.props.dispatch(clearPoVerifyProducts());
        this.setState({
            verifyPopup: false
        })
    }
    inwardOpen() {
        this.props.dispatch(getPoInwardList());
        this.setState({
            inwardPopup: true,
        })
    }
    inwardClose() {
        this.setState({
            inwardPopup: false
        })
    }
    editVendorShow() {
        this.setState({
            editShow: true,
        })
    }
    editVendorClose() {
        const { status } = this.state;
        if (status === "DRAFT") {
            this.setState({
                editShow: false
            })
        } else {
            window.location.hash = `/${role}/vendor_po`;
        }
    }
    deleteItem() {
        this.setState({
            deletePopup: true,
        })
    }
    deletePoHandler() {
        this.props.dispatch(deletePo(this.props.match.params.id));
    }
    deleteItemClose() {
        this.setState({
            deletePopup: false
        })
    }
    handleClose() {
        this.setState({
            deletePopup: false,
        })
    }
    handleShow() {
        this.setState({
            deletePopup: true,
        })
    }
    handleChange = (content) => {
        this.setState({
            dataObj: content
        })
    }
    changeHandler = (e) => {
        const { dropdown } = this.state;
        dropdown.map((item) => {
            if (item?.templateId == e.target.value) {
                this.setState({
                    dataObj: item.termsAndConditions
                })
            }
        })
    }
    submitHandler = (val) => {
        const { dataObj, dropdown, id } = this.state;
        if (dataObj !== "" && dataObj !== null) {
            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
                body: String(dataObj)
            };
            fetch(`${baseUrl}staff/purchaseOrder/${id}/termsAndConditions`, requestOptions)
                .then(res => {
                    if (res.status >= 400) {
                        toast("Something went wrong");
                        throw new Error("Server responds with error!")
                    } else if (res.status == 500) {
                        toast("Something went wrong");
                    }
                    return res.json()
                })
                .then(res => {
                    if (res.status === 200) {
                        toast(res.data);
                        this.props.dispatch(previewPo(this.props.match.params.id));
                        this.editVendorShow();
                    } else {
                        toast(res.data);
                    }
                },
                    err => {
                        this.setState({
                            error: err
                        })
                    })
        } else {
            toast("Please Select Template");
        }
    }
    sendMailOpen() {
        this.setState({
            emailPopup: true,
            editShow: false,
        })
        this.props.dispatch(getPoMailDetails(Number(this.props.match.params.id)));

    }
    sendMailClose() {
        this.setState({
            emailPopup: false
        })
    }
    getCCs(val) {
        this.setState({
            cc: val
        })
    }
    sendPoMailSubmit(values, dispatch) {
        const { cc } = this.state;
        const newItems = cc?.map((item) => item.value);
        let data = {
            "from": values.from,
            "to": values.to,
            "cc": newItems,
            "id": Number(this.props.match.params.id),
            "subject": values.subject
        }
        this.props.dispatch(sendPoMail(data));
        dispatch(reset("SecondaryEmailForm"));
    }
    componentDidUpdate(prevProps) {
        if (prevProps.quotationTemplates !== this.props.quotationTemplates) {
            this.setState({
                dropdown: this.props.quotationTemplates,
            });
        } if (prevProps.poData !== this.props.poData) {
            this.setState({
                dataObj: this.props.poData.termsAndConditions !== "null" ? this.props.poData.termsAndConditions : "",
                vendorItem: this.props.poData
            });
        }  if (prevProps.generateData !== this.props.generateData) {
            this.props.dispatch(previewPo(this.props.match.params.id));
            if (this.props.generateData.status === 200) {
                this.setState({
                    status: "GENERATED",
                    editShow: true
                })
                toast(this.props.generateData.data);
            } else {
                toast(this.props.generateData.data);
            }
        }  if (prevProps.deletePo !== this.props.deletePo) {
            if (this.props.deletePo.status === 200) {
                toast(this.props.deletePo.data);
                setTimeout(() => window.location.hash = `/${role}/vendor_po`, 500);
                this.setState({
                    deletePopup: false,
                    editShow: false
                })
            } else {
                toast(this.props.deletePo.data);
            }
        } if (prevProps.sendData !== this.props.sendData) {
            if (this.props.sendData.status === 200) {
                toast(this.props.sendData.data);
                this.setState({
                    // editShow: false,
                    status: "SENT"
                })
            } else {
                toast(this.props.sendData.data);
            }
        } if (prevProps.cloneData !== this.props.cloneData) {
            if (this.props.cloneData.status === 200) {
                this.setState({
                    editShow: false,
                    clonePopup: false,
                    cloneShow: true,
                    cloneStr: "Purchase Order Cloned Successfully"
                })
                setTimeout(() => this.setState({ cloneShow: false, cloneStr: "", cloned: null }), 1500);
                setTimeout(() => window.location.hash = `/${role}/vendor_po`, 1800);
            } else {
                this.setState({
                    clonePopup: false,
                })
                toast(this.props.cloneData.data);
            }
        } if (prevProps.reviseData !== this.props.reviseData) {
            if (this.props.reviseData.status === 200) {
                toast(this.props.reviseData.message);
                this.setState({
                    editShow: false,
                    clonePopup: false,
                    cloneShow: true,
                    cloneStr: "Purchase Order Revised Successfully"
                })
                setTimeout(() => this.setState({ cloneShow: false, cloneStr: "", cloned: null }), 1500);
                setTimeout(() => window.location.hash = `/${role}/vendor_po`, 1800);
            } else {
                this.setState({
                    clonePopup: false,
                })
                toast(this.props.reviseData.data);
            }
        } if (prevProps.cancelData !== this.props.cancelData) {
            if (this.props.cancelData.status === 200) {
                toast(this.props.cancelData.data);
                this.listAllData();
                this.setState({
                    editShow: false
                })
            } else {
                toast(this.props.cancelData.data);
            }
        } if (prevProps.verifyData !== this.props.verifyData) {
            if (this.props.verifyData.status === 200) {
                this.props.dispatch(clearPoVerifyProducts());
                toast(this.props.verifyData.data);
                this.setState({
                    verifyPopup: false,
                    status: "VERIFIED"
                })
            } else {
                toast(this.props.verifyData.data);
            }
        } if (prevProps.poMailSuccess !== this.props.poMailSuccess) {
            const { poMailSuccess } = this.props;
            if (poMailSuccess.status === 200) {
                this.setState({
                    emailPopup: false,
                    cc: []
                });
                toast(poMailSuccess.data);
            } else {
                toast(poMailSuccess.data);
            }
        }
    }
    componentDidMount() {
        this.props.dispatch(getQutationTemplates());
        if (this.props.match.params.id !== "add") {
            this.props.dispatch(getPoById(this.props.match.params.id));
        }
    }
    render() {
        const { dataObj, editShow, verifyPopup, deletePopup, status, inwardPopup } = this.state;
        const { handleSubmit, ignoredFields, poData } = this.props;

        return (
            <>
                <div>
                    <Navbar />
                    <div className="dashboard-page">
                        <div className="dashboard-sidebar">
                            <Sidebar />
                        </div>
                        <div className="dashboard-main">
                            <Card className="staff-page">
                                <img src={Orange} alt="..." className="head-img" />
                                <h5 className='po_link' onClick={() => window.location.hash = "/" + role + "/vendor_po"}>Purchase Order</h5>
                                <i className="fas fa-arrow-right" style={{ marginTop: "10px" }}></i>
                                <h6>{getTitleStr()}</h6>
                                <hr className="line-new"></hr>
                                <div className="order-page-title">
                                    <Link to={"/" + role + "/vendor_po/vendor-detail/" + this.props.match.params.id} >
                                        Vendor Details
                                    </Link>
                                    <Link to={"/" + role + "/vendor_po/product-detail/" + this.props.match.params.id}>
                                        Product Details
                                    </Link>
                                    {ignoredFields?.includes("FOOTER") ? <></> : <Link to={"/" + role + "/vendor_po/footer/" + this.props.match.params.id} >
                                        Footer
                                    </Link>}
                                    <Link to={"/" + role + "/vendor_po/termsAndConditions/" + this.props.match.params.id} className="active">
                                        Terms & Conditions
                                    </Link>
                                </div>
                                <Row style={{ padding: "20px", paddingLeft: "30px" }}>
                                    <Col md={9}>
                                        <form onSubmit={handleSubmit(this.submitHandler)}>
                                            <Field name="templateId" component="select" className="form-control form-select" onChange={this.changeHandler} >
                                                <option value="" >Select Template *</option>
                                                <option value={5}>purchase</option>
                                            </Field>
                                            <div className="triangle-role"></div>
                                            <br></br>
                                            <Editor
                                                // apiKey="2j6wvm4x2pa25n680e9pbtskcfhyi2qdsvjut2c24rm83049"
                                                apiKey="8n2aarggnqa5hgtufqzwy5feg5vxivofahd8jh5x31pwslb0"
                                                value={dataObj}
                                                init={{
                                                    menubar: true,
                                                    plugins: 'lineheight print importcss tinydrive searchreplace autolink autosave save directionality  visualblock  link  charmap hr  insertdatetime  wordcount  textpattern noneditable ',
                                                    toolbar: 'lineheightselect',
                                                    lineheight_formats: "2pt 3pt 4pt 5pt 6pt 7pt 8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 26pt 28pt 32pt 36pt 38pt 40pt",
                                                    height: 350,
                                                }}
                                                onEditorChange={this.handleChange}
                                            />
                                            <div className="customer-sub" style={{ marginTop: "30px" }}>
                                                <Col md={3}>
                                                    <button type="button" style={{ marginLeft: "-20px" }} className="cancel-btn" onClick={() => this.prevPage()}>Cancel</button>
                                                </Col>
                                                <Col md={4}></Col>
                                                <Col md={3}>
                                                    <button type="submit" style={{ marginLeft: "180px" }} className="modal-btn save">
                                                        Save & Preview
                                                    </button>
                                                </Col>
                                                <Col md={2}>
                                                </Col>
                                            </div>
                                        </form>
                                    </Col>
                                </Row>
                            </Card>
                            <VenderPoView
                                show={editShow}
                                onHide={this.editVendorClose}
                                goTo={this.goToPoPage}
                                data={status}
                                item={poData}
                                generate={this.generatePoHandler}
                                delete={this.handleShow}
                                send={this.sendPohandler}
                                clone={this.clonePoHandler}
                                revise={this.revisePoHandler}
                                verify={this.verifyShowPopup}
                                cancel={this.cancelPoHandler}
                                sendMail={this.sendMailOpen}
                                inwardOpen={this.inwardOpen}
                            />
                            <VerifyProducts
                                show={verifyPopup}
                                onHide={this.verifyClosePopup}
                                data={poData}
                            />
                            <DeletePopUp
                                show={deletePopup}
                                onHide={this.deleteItemClose}
                                deleteItem={this.deletePoHandler}
                            />
                            <InwardProducts
                                show={inwardPopup}
                                id={Number(this.props.match.params.id)}
                                onHide={this.inwardClose}
                                listAllData={() => console.log("")}
                            />
                            <CloneStatus
                                show={this.state.clonePopup}
                                str={this.state.cloneStr}
                                onHide={false}
                            />
                            <ClonePopup
                                show={this.state.cloneShow}
                                str={this.state.cloneStr}
                                onHide={false}
                                cloned={this.state.cloned}
                            />
                            <EmailPopup
                                show={this.state.emailPopup}
                                onHide={this.sendMailClose}
                                onSubmit={this.sendPoMailSubmit}
                                getCC={this.getCCs}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.qutation.loading,
        quotationTemplates: state.qutation.quotationTemplates,
        poData: state.po.poData,
        ignoredFields: state.po.poData?.ignoredFields,
        vendor: state.vendor.vendor,
        generateData: state.po.generateData,
        sendData: state.po.sendData,
        deletePo: state.po.deletePo,
        cloneData: state.po.cloneData,
        reviseData: state.po.reviseData,
        cancelData: state.po.cancelData,
        verifyData: state.po.verifyData,
        confirmQuotDropdown: state.boq.confirmQuotDropdown,
        convertProducts: state.po.convertProducts,
    }
}
const Form = reduxForm({
    form: "ContentForm",
    destroyOnUnmount: false,
    enableReinitialize: true,
})(PoTermsAndConditions);

export default connect(mapStateToProps)(Form);





